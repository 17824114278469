// Based mostly on https://github.com/Uniswap/interface/blob/main/src/theme/index.tsx

export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  gray50: '#FFE8E6',
  gray100: '#FFD9D7',
  gray150: '#FFC5C0',
  gray200: '#FFAAA6',
  gray250: '#FF8E89',
  gray300: '#FF716B',
  gray350: '#FF564E',
  gray400: '#FF3B30',
  gray450: '#FF2618',
  gray500: '#FF0D00',
  gray550: '#E40900',
  gray600: '#CC0500',
  gray650: '#B30000',
  gray700: '#990000',
  gray750: '#800000',
  gray800: '#660000',
  gray850: '#4C0000',
  gray900: '#330000',
  gray950: '#190000',

  pink50: '#FFE8E6',
  pink100: '#FFD9D7',
  pink200: '#FFAAA6',
  pink300: '#FF716B',
  pink400: '#FF3B30',
  pink500: '#FF0D00',
  pink600: '#CC0500',
  pink700: '#990000',
  pink800: '#660000',
  pink900: '#330000',

  pinkVibrant: '#FF0D00',

  red50: '#FAECEA',
  red100: '#FED5CF',
  red200: '#FEA79B',
  red300: '#FD766B',
  red400: '#FA2B39',
  red500: '#C4292F',
  red600: '#891E20',
  red700: '#530F0F',
  red800: '#380A03',
  red900: '#240800',

  redVibrant: '#F14544',

  yellow50: '#F6F2D5',
  yellow100: '#DBBC19',
  yellow200: '#DBBC19',
  yellow300: '#BB9F13',
  yellow400: '#A08116',
  yellow500: '#866311',
  yellow600: '#5D4204',
  yellow700: '#3E2B04',
  yellow800: '#231902',
  yellow900: '#180F02',

  yellowVibrant: '#FAF40A',

  gold200: '#EEB317',
  gold400: '#B17900',
  goldVibrant: '#FEB239',

  green50: '#E3F3E6',
  green100: '#BFEECA',
  green200: '#76D191',
  green300: '#40B66B',
  green400: '#209853',
  green500: '#0B783E',
  green600: '#0C522A',
  green700: '#053117',
  green800: '#091F10',
  green900: '#09130B',

  greenVibrant: '#5CFE9D',

  // Blues changed to red
  blue50: '#FFE8E6',
  blue100: '#FFD9D7',
  blue200: '#FFAAA6',
  blue300: '#FF716B',
  blue400: '#FF3B30',
  blue500: '#FF0D00',
  blue600: '#CC0500',
  blue700: '#990000',
  blue800: '#660000',
  blue900: '#330000',

  blueVibrant: '#FF0D00',

  magenta300: '#FD82FF',
  magentaVibrant: '#FC72FF',

  purple300: '#8440F2',
  purple900: '#1C0337',
  purpleVibrant: '#6100FF',

  networkEthereum: '#627EEA',
  networkOptimism: '#FF0420',
  networkOptimismSoft: 'rgba(255, 4, 32, 0.16)',
  networkPolygon: '#A457FF',
  networkArbitrum: '#28A0F0',
  networkBsc: '#F0B90B',
  networkPolygonSoft: 'rgba(164, 87, 255, 0.16)',
  networkEthereumSoft: 'rgba(98, 126, 234, 0.16)',
  networkBase: '#0052FF',

  // New colors
  neutral1_dark: '#FFFFFF',
  neutral2_dark: '#9B9B9B',
  neutral3_dark: '#5E5E5E',
  surface1_dark: '#131313',
  surface2_dark: '#1B1B1B',
  surface3_dark: '#FFFFFF12',
  surface4_dark: '#FFFFFF20',
  surface5_dark: '#00000004',
  accent1_dark: '#D60A0A',
  accent2_dark: '#311C1C',

  neutral1_light: '#222222',
  neutral2_light: '#7D7D7D',
  neutral3_light: '#CECECE',
  surface1_light: '#FFFFFF',
  surface2_light: '#F9F9F9',
  surface3_light: '#22222212',
  surface4_light: '#FFFFFF64',
  surface5_light: '#00000004',
  accent1_light: '#D60A0A',
  accent2_light: '#FFEFFF',

  success: '#40B66B',
  critical: '#FF5F52',

  scrim: 'rgba(0, 0, 0, 0.60)',
}

type Theme = typeof darkTheme

const commonTheme = {
  white: colors.white,
  black: colors.black,

  chain_1: colors.networkEthereum,
  chain_3: colors.yellow400,
  chain_4: colors.pink400,
  chain_5: colors.green400,
  chain_10: colors.networkOptimism,
  chain_137: colors.networkPolygon,
  chain_42: colors.networkArbitrum,
  chain_56: colors.networkBsc,
  chain_420: colors.networkOptimism,
  chain_42161: colors.networkArbitrum,
  chain_421613: colors.networkArbitrum,
  chain_80001: colors.networkPolygon,
  chain_43114: colors.networkOptimism,
  chain_137_background: colors.purple900,
  chain_10_background: colors.red900,
  chain_43114_background: colors.red900,
  chain_42161_background: colors.blue900,
  chain_84531: colors.networkBase,
  chain_56_background: colors.networkBsc,
  promotional: colors.magenta300,

  brandedGradient: 'linear-gradient(139.57deg, #FF3B30 4.35%, #FFAAA6 96.44%)',
  promotionalGradient: colors.accent1_light,
}

export const darkTheme = {
  ...commonTheme,

  background: colors.black,

  neutral1: colors.neutral1_dark,
  neutral2: colors.neutral2_dark,
  neutral3: colors.neutral3_dark,

  surface1: colors.surface1_dark,
  surface2: colors.surface2_dark,
  surface3: colors.surface3_dark,
  surface4: colors.surface4_dark,
  surface5: colors.surface5_dark,

  accent1: colors.accent1_dark,
  accent2: colors.accent2_dark,
  success: colors.success,
  critical: colors.critical,
  scrim: colors.scrim,
}

export const lightTheme: Theme = {
  ...commonTheme,

  background: colors.white,

  neutral1: colors.neutral1_light,
  neutral2: colors.neutral2_light,
  neutral3: colors.neutral3_light,

  surface1: colors.surface1_light,
  surface2: colors.surface2_light,
  surface3: colors.surface3_light,
  surface4: colors.surface4_light,
  surface5: colors.surface5_light,

  accent1: colors.accent1_light,
  accent2: colors.accent2_light,

  success: colors.success,
  critical: colors.critical,
  scrim: colors.scrim,
}
